import React, { useEffect, useState, useRef } from "react";

import withRouter from "components/Common/withRouter";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Label,
  FormFeedback,

  Input,


} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";



//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import axios from 'axios';

import "jspdf-autotable";



import {

} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Sms  | DemirYıldız B2B PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getIsActive, setIsActive] = useState(false);
  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (contactEdit && contactEdit.Sector_name) || "",
      desc: (contactEdit && contactEdit.Description) || "",

    },
    validationSchema: Yup.object({
      title: Yup.string(3).required("Başlığı Giriniz"),


    }),
    onSubmit: values => {
 
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.sector,
  }));

  const [isEdit, setIsEdit] = useState(false);
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);
  const [getCategorySecondID, setCategorySecondID] = useState(null);
  const [getCategorySecond, setCategorySecond] = useState([]);
  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersDemiryildiz/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }


  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetSector());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);



  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const _setChoiceCategorySecond = arg => {
    setCategorySecondID(arg.target.value)
  }
  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="SMS GÖNDER" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>


                  <Row>
                    <Col xs={12}>

                      <div className="mb-3">
                        <Label className="form-label">Kullanıcı Seçiniz</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                          defaultValue={getCategoryID}
                          onChange={(text) => _setChoiceCategory(text)}
                          value={getCategoryID} // Change this line
                        >
                            <option value="">Lütfen Seçim Yapınız</option>

                          {getCategory.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Users_NameSurname}</option>
                          ))}

                        </Input>
                        {validation.touched.wsText && validation.errors.wsText ? (
                          <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                        ) : null}
                      </div>


              
                      <div className="mb-3">
                        <Label className="form-label">Sms Başlık</Label>
                        <Input
                          name="title"
                          label="title"
                          type="text"
                          placeholder="Sms Başlık Giriniz"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.title || ""}
                          invalid={
                            validation.touched.title &&
                              validation.errors.title
                              ? true
                              : false
                          }
                        />
                        {validation.touched.title &&
                          validation.errors.title ? (
                          <FormFeedback type="invalid">
                            {validation.errors.title}
                          </FormFeedback>
                        ) : null}
                      </div>


                      <div className="mb-3">
                        <Label className="form-label">Sms Açıklama</Label>
                        <Input
                          name="desc"
                          label="desc"
                          type="text"
                          placeholder="Sms Açıklama Giriniz"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.desc || ""}
                          invalid={
                            validation.touched.desc &&
                              validation.errors.desc
                              ? true
                              : false
                          }
                        />
                        {validation.touched.desc &&
                          validation.errors.desc ? (
                          <FormFeedback type="invalid">
                            {validation.errors.desc}
                          </FormFeedback>
                        ) : null}
                      </div>


                    </Col>
                  </Row>
                  <Row>



                    <Col>
                      <div className="text-end">
                        <button
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          SMS GÖNDER
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
