import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";


// Pages Components
import WelcomeComp from "./WelcomeComp";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch } from "react-redux";
import Line from "../AllCharts/echart/linechart"
import Doughnut from "../AllCharts/echart/doughnutchart"
import Slidewithfade from "./CarouselTypes/slidewithfade";


import {
  getChats as onGetChats,
} from "store/actions";

import {
  getClasses as onGetClasses,
  getCourseSchedulesMega as ongetCourseSchedulesMega,
  getProjectsNew as onGetProjectsNew,
  getUsers as onGetUsers,
  getAdvert as onGetAdvert,
  getProduct as onGetProduct,
  getReservation as onGetReservation
} from "store/contacts/actions";
const Dashboard = props => {

  const dispatch = useDispatch();

  const { reservation } = useSelector(state => ({
    reservation: state.contacts.reservation,
  }));

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));

  const reports = [
    { title: "Toplam Sipariş", iconClass: "bx-archive-in", description: reservation.length },
    {
      title: "Toplam Üyeler",
      iconClass: "bx-purchase-tag-alt",
      description:  users.length,
    }
  ];




  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
    }
  }, [dispatch, users]);

  useEffect(() => {
    if (reservation && !reservation.length) {
      dispatch(onGetReservation());
    }
  }, [dispatch, reservation]);



  //meta title
  document.title = "Anasayfa | DemirYıldız B2B Paneli";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Yönetim")}
            breadcrumbItem={props.t("Anasayfa")}
          />

          <Row>
            <Col xl="4">
              <WelcomeComp />
            </Col>
            <Col xl="8">
              <Row>
                {/* Reports Render */}
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                            <span className="avatar-title rounded-circle bg-primary">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"
                                }
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>

                 
                    
                         

                  </Col>
                ))}
              </Row>

          
              <Col xl="8">
            <Slidewithfade />
            </Col>
             
            </Col>

           



          </Row>

          <Row>
          <Col xl="4">
          <Row>
          <div id="line-chart" className="e-chart">
                    <Line reservation={reservation} dataColors='["--bs-success"]'/>
                  </div>

                  </Row>
                  
                  </Col>

                  <Col xl="4">
          <Row>
         
 <div id="doughnut-chart" className="e-chart">
                    <Doughnut  reservation={reservation} dataColors='["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]'/>
                  </div>
                  </Row>
                  
                  </Col>
                  </Row>

         
        </Container>
      </div>

   
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
