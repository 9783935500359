import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Button
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NameSurname,Date } from "./contactlistCol";
import DateModal from "components/Common/DateModal";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';

import "jspdf-autotable";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dropzone from 'react-dropzone-uploader';
import {ExportToExcel2} from '../../../ExportToExcel2'
import Slider from "react-rangeslider"

import {
  getReservation as onGetReservation,
  addReservation as onAddProduct,
  updateReservation as onUpdateProduct,
  deleteReservation as onDeleteProduct,
} from "store/contacts/actions";
import { isEmpty } from "lodash";
import classnames from "classnames";
import FilterModal from "components/Common/FilterModal";

//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Siparişler | DemirYıldız B2B PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getHtmlDataLong, setHtmlDataLong] = useState();
  const [getFileSubMedia, setFileSubMedia] = useState([]);
  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);

  const [getSubMediaID, setSubMediaID] = useState();

  const [getSelectDateFinish, setSelectDateFinish] = useState(null);
  const [getSelectDate, setSelectDate] = useState(null);
  const [getExcelModal, setExcelModal] = useState(false)

  const [passedSteps, setPassedSteps] = useState([1]);

  
  const submitAdd = tab => {
      
    getSelectedValuesProduct.map(async (item, index) => {
      const selectedItem = getCategory.find(item2 => item2.ID == item);

      const newDetail = {
        TotalPrice:quantities[item] * parseInt(selectedItem.Product_CashPrice),
        Quantity:quantities[item],
        Orders_ID:getOrderID,
        Products_ID: parseInt(item)
      };
      

      await axios.post('https://getjob.stechomeyazilim.info:5101/addOrderDetailDemir/send', newDetail) .then(async(res) => {

    
    const updateData = {
     Orders_TotalPrice:totalCashPrice,
    }

    await axios.patch(`https://getjob.stechomeyazilim.info:5101/patchOrderDemir/${getOrderID}`, updateData).then(async (res) => {

   //   showToast('Epilepsi Eğitim', "Şifreniz Başarıyla Değiştirildi!", 'success')

    })
      console.log("lmsdşl",res)

      toggle();
      dispatch(onGetReservation());

      setQuantities({})
      setCategory([])
      setSelectedValuesProduct([])
      setstep(0)
      setActiveTab(1)
      setUserID(null)
      setPaymentID(null)
      })
      
    })

  }

  const toggleTab = tab => {

    console.log("12321",tab,getPaymentID)

    if(tab > 0){
    if(tab == 2){
      var localDate = moment().local();

      const newUser = {
        Users_ID:getUserID,
        Payment_Select_ID:getPaymentID,
        Created_DateTime:localDate,
        //Orders_TotalPrice:values["price"],
        Orders_IsActive:getIsActive,
        //Payment_Type:getPaymentID.toString()
      };

      axios.post('https://getjob.stechomeyazilim.info:5101/addOrderDemir/send', newUser) .then((res) => {

      console.log("lmsdşl",res.data.value)

      setOrderID(res.data.value[0].ID)

      if(res.data.value.length >0){
        if (activeTab !== tab) {
          const modifiedSteps = [...passedSteps];
          modifiedSteps.push(tab);
          setActiveTab(tab);
          setPassedSteps(modifiedSteps);
        }
  
      }
     

      })
      
    }
    

  }
  };


  const toggleTabEdit = (tab,itemm) => {

    console.log("12321",tab,getPaymentID)

    if(tab > 0){
    if(tab == 2){
      var localDate = moment().local();

      const newUser = {
        Users_ID:getUserID,
        Payment_Select_ID:getPaymentID,
        Created_DateTime:localDate,
        //Orders_TotalPrice:values["price"],
        Orders_IsActive:getIsActive,
        //Payment_Type:getPaymentID.toString()
      };

      axios.post('https://getjob.stechomeyazilim.info:5101/addOrderDemir/send', newUser) .then((res) => {

      console.log("lmsdşl",res.data.value)

      setOrderID(res.data.value[0].ID)

      if(res.data.value.length >0){
        if (activeTab !== tab) {
          const modifiedSteps = [...passedSteps];
          modifiedSteps.push(tab);
          setActiveTab(tab);
          setPassedSteps(modifiedSteps);
        }
  
      }
     

      })
      
    }
    

  }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
    
    },
    validationSchema: Yup.object({



    }),
    onSubmit: values => {
      var localDate = moment().local();

      if (isEdit) {

        const updateUser = {
          ID: contactEdit.ID,
          Users_ID:getUserID,
          Payment_Select_ID:getPaymentID,
          Orders_TotalPrice:values["price"],
          Orders_IsActive:getIsActive,
          TotalPrice:getCategoryID,
          Quantity:step,
          Orders_ID:null,
          Products_ID: getCategoryID
        };
        dispatch(onUpdateProduct(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newUser = {
          Users_ID:getUserID,
          Payment_Select_ID:getPaymentID,
          Created_DateTime:localDate,
          //Orders_TotalPrice:values["price"],
          Orders_IsActive:getIsActive
        };

      
        dispatch(onAddProduct(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [step, setstep] = useState(0)
  const [getIsActive, setIsActive] = useState(false);


  const { users } = useSelector(state => ({
    users: state.contacts.reservation,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalModal, setModalModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);
  const [getOrderDetail, setOrderDetail] = useState([]);

  const [isEdit, setIsEdit] = useState(false);
  const [getCategoryID, setCategoryID] = useState(null);
  const [getCategory, setCategory] = useState([]);
  const [getPayment, setPayment] = useState([]);
  const [getUser, setUser] = useState([]);

  const [getType, setType] = useState(false);


  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
    
      {
        Header: "Ürün Adeti",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
          
              <div className="">
                 <span className="">
                  {cellProps.Orders_Detail.length}
                </span>
              </div>
          
          </>
        ),
      },
      
      {
        Header: "Tarihi",
        accessor: "Created_DateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Vade Tarihi",
        accessor: "Created_DateTime1",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },

      {
        Header: "Toplam FİYAT",
        accessor: "Orders_TotalPrice",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Kullanıcı",
        accessor: "Users.Users_NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Ödeme Tipi",
        accessor: "Payment_Select.Payment_Select_Text",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

<Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick2(userData);
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="tooltip" />
                <UncontrolledTooltip placement="top" target="tooltip">
                  Detay
                </UncontrolledTooltip>
              </Link>

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsDemir/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  const _getPaymentType = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getPaymentSelectDemiryildiz/select/`)
      .then((res) => {
        setPayment(res.data)
      })
  }
  
  const [getFilterCheck, setFilterCheck] = useState(false);
  const [getFilterModal, setFilterModal] = useState(false);
  const [getFilterCheckModal, setFilterCheckModal] = useState(false);
  const [getSelectedValuesProduct, setSelectedValuesProduct] = useState([]);
  const [getFilterData, setFilterData] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [totalCashPrice, setTotalCashPrice] = useState(0);

  useEffect(() => {
    // Seçilen ürünlerin toplam fiyatını hesapla
    const total = getSelectedValuesProduct.reduce((sum, selectedID) => {
      const selectedItem = getCategory.find(item => item.ID == selectedID);
      const quantity = quantities[selectedID] || 1;
      return sum + (selectedItem ? selectedItem.Product_CashPrice * quantity : 0);
    }, 0);
    setTotalCashPrice(total);
  }, [getSelectedValuesProduct, quantities, getCategory]);

  
  const _getAuthData2 = async () => {

    await axios.get(`https://getjob.stechomeyazilim.info:5101/getUsersDemir/select/`)
      .then((res) => {
        setUser(res.data)
      })
  }

  useEffect(() => {
    _getPaymentType()
    _getAuthData()
    _getAuthData2()

    if (users && !users.length) {
      dispatch(onGetReservation());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleModal = () => {
    setModalModal(!modalModal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };


  const handleUserClickMedia = (arg) => {
    const user = arg;

    setSubMediaID(user.ID)

    setFileSubMedia(user.Products_Image)

    toggleMedia();
  };

  
    
  const handleUserClick2 = (arg) => {
    const user = arg;
    
    setOrderDetail(arg.Orders_Detail)
    toggleModal();
  };

  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

   
    setIsEdit(true);
    setUserID(user.Users_ID)
    setPaymentID(user.Payment_Select_ID)

    
    setIsActive(user.Orders_IsActive)
    setSelectedValuesProduct(user.Orders_Detail)

    console.log("ksmdkfşl",user.Orders_Detail)
   /* user.Orders_Detail.map((item, index) => (
     setSelectedValuesProduct(item)
    )) */


    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteProduct(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };


  const Preview = ({ meta }) => {
    const { name, percent, status, previewUrl } = meta;
    return (
      <div className="preview-box">
        <img src={previewUrl} /> <span className="name">{name}</span> - <span className="status">{status == "done" ? "Başarıyla Yüklendi" : "Yükleniyor"}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
      </div>
    )
  }

  const _deleteMedia = async (itemData) => {

    try {

      axios.delete(`https://getjob.stechomeyazilim.info:5101/deleteProductImageLuxun/${itemData.ID}`).then(async (res) => {

        await axios.get(`https://getjob.stechomeyazilim.info:5101/getProductsMediaLuxun/select/${getSubMediaID}`)
          .then((res) => {
            setFileSubMedia(res.data)
          })


      })
    } catch (error) {
      //yield put(updateUserFail(error))
    }


    /*  const filteredData = getFileSubMediaAdd.filter(item => item.ID !== itemData.ID )
      setFileSubMediaAdd(filteredData);*/

  }



  const getUploadParams = ({ file }) => {
    const body = new FormData();
    body.append('dataFile', file);
    return { url: 'https://megaadmin.stechomeyazilim.info:8080/uploadfile', body }
  }

  const handleChangeStatusBanner = ({ xhr }) => {
    if (xhr) {
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          const result = JSON.parse(xhr.response)
          setFileSubMediaAdd(oldArray => [{ fileName: result.file.filename, fileType: result.file.mimetype }, ...oldArray]);
        }
      }
    }
  }


  const exportToCSV = () => {

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    setExcelModal(false)

    let fileName = "GirisYapmayanlar"


    
    const data2 =  users.filter(x=> x.Last_Login_Date == null).map(elt=> [
      elt.NameSurname,
      elt.Last_Login_Date,
        ]);
    
    const ws = XLSX.utils.json_to_sheet(data2);
    
    ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
    ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
    
    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };


    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension)

  }

  const handleChangeTextFinish = (text) => {

    setSelectDateFinish(text.target.value)

  }
      const handleChangeText = (text) => {

      setSelectDate(text.target.value)

    }

    const exportToCSVLoginLastMonth= () => {


      const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
  
      setExcelModal(false)
  
      console.log("lksdklf")
      let fileName = getSelectDate + getSelectDateFinish
  
      const data2 = users.filter(x => x.Created_DateTime >= getSelectDate && x.Created_DateTime <= getSelectDateFinish).map(elt => [
      elt.Orders_TotalPrice,
      elt.Payment_Select.Payment_Select_Text,
      moment(elt.Created_DateTime).format('DD/MM/YYYY'),
        ]);
  
        const ws = XLSX.utils.json_to_sheet(data2);
      
        ws["A1"] = { v: "Tutar", t: "s" };
        ws["B1"] = { v: "Ödeme Tipi", t: "s" };
        ws["C1"] = { v: "Tarih", t: "s" };

        
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension)
  
    }
    const exportToCSVDontLoginLastMonth= () => {


      const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
  
      setExcelModal(false)
  
      let fileName = "Son1AyGirisYapmayanlar"
  
  
      const data2 = users.filter(x => x.Last_Login_Date < getSelectDate || x.Last_Login_Date > getSelectDateFinish).map(elt => [
      elt.NameSurname,
      moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
        ]);
  
        const ws = XLSX.utils.json_to_sheet(data2);
      
        ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
        ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
      
      
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension)
  
    }
    const [getDateClick, setDateClick] = useState(null);
    const [getUserID, setUserID] = useState(null);
    const [getPaymentID, setPaymentID] = useState(null);


    const onClickExcelLogin = (order) => {
      setDateClick(order);
      setExcelModal(true);
      setType('login')
    }

    const exportToCSVLogin = () => {
  
      const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
  
      setExcelModal(false)
  
      let fileName = "GirisYapmayanlar"
  
    const data2 =  users.filter(x=> x.Last_Login_Date != null).map(elt=> [
      elt.NameSurname,
      moment(elt.Last_Login_Date).format('DD/MM/YYYY'),
        ]);
  
        const ws = XLSX.utils.json_to_sheet(data2);
      
        ws["A1"] = { v: "Kullanıcı Adı", t: "s" };
        ws["B1"] = { v: "Son Giriş Tarihi", t: "s" };
      
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension)
  
    }

    const total = getFilterCheckModal == true ? getFilterData.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0) : users.reduce((sum, order) => sum + (order.Orders_TotalPrice || 0), 0);

    
  const _setChoiceUser = arg => {
    setUserID(arg.target.value)
  }

  const _setChoicePaymentType = arg => {
    setPaymentID(arg.target.value)
  }
  
  const _setChoiceCategory = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedValuesProduct(selectedOptions);
  }



  const handleQuantityChange = (id, value) => {
    setQuantities({
      ...quantities,
      [id]: Number(value)
    });
  };

  const [activeTab, setActiveTab] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [getOrderID, setOrderID] = useState(null);


  const _onFilterClick = (val,active) => {
  

    if(active == true){
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)
    }else{
      const dataFilter =  users.filter(x=> x.Users_ID == val)
      setFilterData(dataFilter)
      setFilterCheckModal(true)

      setFilterModal(false)

    }
 
  }



  return (
    <React.Fragment>
    

<DateModal
        onDownloadClick={exportToCSV}
        handleChange={handleChangeText}
        handleChangeFinish={handleChangeTextFinish}
        onFilterClick={getType == "login" ? exportToCSVLoginLastMonth: exportToCSVDontLoginLastMonth}
        show={getExcelModal}
        type={getType}
        onCloseClick={() => setExcelModal(false)}
      />
      

      <FilterModal
handleSelectChangeCompany= {_onFilterClick}
       
        show={getFilterModal}
        getCategoryCompany={getUser}
        onCloseClick={() => setFilterModal(false)}
     
      />

         
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Siparişler" />



          {getFilterCheckModal == false ? 
<Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterModal(true)}
              >
                <i className="mdi mdi-plus me-1" />
                Firmaya Göre Filtrele
              </Button>:
              <Button
                type="button"
                color="danger"
                className="btn-rounded  mb-2 me-2"
                onClick={()=> setFilterCheckModal(false)}
              >
                <i className="mdi mdi-plus me-1" />
                Listeyi Sıfırla
              </Button>}

          <ExportToExcel2 title={"Tarihe Göre Excel"} onDownloadClick={exportToCSVLoginLastMonth} onClickExcel={onClickExcelLogin} apiData={users} fileName={"fileName"} />
          <div>
            <div>
            <Label className="form-label">Toplam Tutar: {total} TL</Label>
          </div>
          <div>
          <Label className="form-label">Vadesi Gelmemiş Tutar : {total} TL</Label>

          </div>

          <div>
          <Label className="form-label">Vadesi Geçmiş Tutar : {total} TL</Label>
          </div>
          </div>

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <TableContainer
                    columns={columns}
                    data={getFilterCheckModal == true ? getFilterData : getFilterCheck == true ? getFilterData : users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddOrder={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

        

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Sipariş Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >

<div id="kyc-verify-wizard" className="wizard clearfix">
                          <div className="steps clearfix">
                            <ul>
                              <NavItem
                                className={classnames({
                                  current: activeTab === 1,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 1,
                                  })}
                                  onClick={() => {
                                    toggleTab(1);
                                  }}
                                  disabled={!passedSteps.includes(1)}
                                >
                                  <span className="number">1.</span>
                                Sipariş Bilgileri
                                </NavLink>
                              </NavItem>
                              <NavItem
                                className={classnames({
                                  current: activeTab === 2,
                                })}
                              >
                                <NavLink
                                  className={classnames({
                                    active: activeTab === 2,
                                  })}
                                  onClick={() => {
                                    toggleTab(2);
                                  }}
                                  disabled={!passedSteps.includes(2)}
                                >
                                  <span className="number">2.</span>
                                  Ürün Bilgileri
                                </NavLink>
                              </NavItem>
                           
                            </ul>
                          </div>
                          <div className="content clearfix">
                            <TabContent
                              activeTab={activeTab}
                              className="twitter-bs-wizard-tab-content"
                            >
                              <TabPane tabId={1} id="personal-info">
                                <Form>
                                  <Row>
                                    <Col lg="6">
                                      <FormGroup className="mb-3">
                                    
                        <Label className="form-label">Ödeme Tipini Seçiniz</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                          defaultValue={getPaymentID}
                          onChange={(text) => _setChoicePaymentType(text)}
                          value={getPaymentID} // Change this line
                        >
                            <option value="">Lütfen Seçim Yapınız</option>

                          {getPayment.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Payment_Select_Text}</option>
                          ))}

                        </Input>
                        {validation.touched.wsText && validation.errors.wsText ? (
                          <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                        ) : null}
                    
                                      </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                      <FormGroup className="mb-3">
                                  
                        <Label className="form-label">Kullanıcı Seçiniz</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          className="form-select"
                          defaultValue={getUserID}
                          onChange={(text) => _setChoiceUser(text)}
                          value={getUserID} // Change this line
                        >
                            <option value="">Lütfen Seçim Yapınız</option>

                          {getUser.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Users_NameSurname}</option>
                          ))}

                        </Input>
                        {validation.touched.wsText && validation.errors.wsText ? (
                          <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                        ) : null}
                   
                                      </FormGroup>
                                    </Col>
                                    
                                    <div className="mb-3">
                      <Label className="form-label">Vade Tarihi</Label>
                      <Input
                        name="expiryDate"
                        type="date"
                        // value={orderList.orderdate || ""}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.expiryDate || ""}
                        invalid={
                          validation.touched.expiryDate && validation.errors.expiryDate ? true : false
                        }
                      />
                      {validation.touched.expiryDate && validation.errors.expiryDate ? (
                        <FormFeedback type="invalid">{validation.errors.expiryDate}</FormFeedback>
                      ) : null}
                    </div>
                  
                                    <Col lg={6}>
                                        <div className="mb-3">
                                          
                      <div className="square-switch">

<input
  type="checkbox"
  id="square-switch1"
  className="switch"
  defaultChecked={getIsActive}
  onChange={() =>
    setIsActive(!getIsActive)
  }
/>
<label
htmlFor="square-switch1"
  data-on-label="Aktif "
  data-off-label="Pasif"
/>
</div>

                                        </div>
                                      </Col>
                                  </Row>

                            
                                </Form>
                              </TabPane>
                              <TabPane tabId={2} id="confirm-email">
                                <div>
                                  <Form>
                                    <Row>
                                    <Col lg={12}>
                                      <div className="mb-3">
                        <Label className="form-label">Ürün Seçiniz</Label>
                        <Input
                          name="paymentStatus"
                          type="select"
                          multiple
                          className="form-select"
                          defaultValue={getCategoryID}
                          onChange={(text) => _setChoiceCategory(text)}
                          value={getCategoryID} // Change this line
                        >
                          {getCategory.map((item, index) => (
                            <option value={item.ID} key={item.ID}>{item.Products_Name + " " + item.Products_StockCode + " " + item.Product_CashPrice + " TL"}</option>
                          ))}

                        </Input>
                        {validation.touched.wsText && validation.errors.wsText ? (
                          <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                        ) : null}
                      </div>

                                      </Col>
                                      </Row>
                                      
                                    <Row>
                                      <Col lg={6}>

    {isEdit == true ? getSelectedValuesProduct.map((selectedID) => {
          const selectedItem = getCategory.find(item => item.ID == selectedID.ID);

          return (
            <Col lg={12} key={selectedID}>
              <div className="mb-3">
                <Label className="form-label">{selectedItem ? selectedItem.Products_Name : 'Ürün'}</Label>
                <Input
                  type="number"
                  className="form-control"
                  value={quantities[selectedID] || 1}
                  onChange={(e) => handleQuantityChange(selectedID, e.target.value)}
                  min="1"
                />

<Label className="form-label">{parseInt(selectedItem.Product_CashPrice) * parseInt(quantities[selectedID])} TL</Label>
              </div>
            </Col>
          );
        }) : getSelectedValuesProduct.map((selectedID) => {
          const selectedItem = getCategory.find(item => item.ID == selectedID);

          return (
            <Col lg={12} key={selectedID}>
              <div className="mb-3">
                <Label className="form-label">{selectedItem ? selectedItem.Products_Name : 'Ürün'}</Label>
                <Input
                  type="number"
                  className="form-control"
                  value={quantities[selectedID] || 1}
                  onChange={(e) => handleQuantityChange(selectedID, e.target.value)}
                  min="1"
                />

<Label className="form-label">{parseInt(selectedItem.Product_CashPrice) * parseInt(quantities[selectedID])} TL</Label>
              </div>
            </Col>
          );
        })}

                                     
                                      </Col>

                                      <Label className="form-label">Toplam Fiyat : {totalCashPrice} TL</Label>

                                    </Row>
                                 
                                  </Form>
                                </div>
                              </TabPane>
                              <TabPane tabId={3} id="doc-verification">
                                <h5 className="font-size-14 mb-3">
                                  Upload document file for a verification
                                </h5>
                                <div className="kyc-doc-verification mb-3">
                                  <Dropzone
                                    onDrop={acceptedFiles =>
                                      handleAcceptedFiles(acceptedFiles)
                                    }
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick"
                                          {...getRootProps()}
                                        >
                                          <Input {...getInputProps()} />
                                          <div className="mb-3">
                                            <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                          </div>
                                          <h4>
                                            Drop files here or click to upload.
                                          </h4>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <div
                                    className="dropzone-previews mt-3"
                                    id="file-previews"
                                  >
                                    {selectedFiles.map((f, i) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={i + "-file"}
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  className="avatar-sm rounded bg-light"
                                                  alt={f.name}
                                                  src={f.preview}
                                                />
                                              </Col>
                                              <Col>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>
                                                    {f.formattedSize}
                                                  </strong>
                                                </p>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                          <div className="actions clearfix">
                            <ul role="menu" aria-label="Pagination">
                              <li
                                className={
                                  activeTab === 1
                                    ? "previous disabled"
                                    : "previous"
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab - 1);
                                  }}
                                >
                                  Geri
                                </Link>
                              </li>
                              {activeTab === 2 ? 
                                  
                                  <Link
                                  to="#"
                                  onClick={() => {
                                    submitAdd(activeTab - 1);
                                  }}
                                >
                                    {totalCashPrice} TL Sepeti Kaydet
                                   </Link>
                                
                                 : 
                                 <li
                                className={
                                  activeTab === 2 ? "next disabled" : "next"
                                }
                              >
                                {isEdit == true ? 
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTabEdit(activeTab + 1);
                                  }}
                                >
                                  Siparişi Düzenle Ve Ürün Detaylarını Gir
                                </Link> : 
                                <Link
                                  to="#"
                                  onClick={() => {
                                    toggleTab(activeTab + 1);
                                  }}
                                >
                                  Siparişi Oluştur Ve Ürün Detaylarını Gir
                                </Link>}

                              </li>}
                            </ul>
                          </div>
                        </div>

                      
                        <Row>



                        
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>


                

                  <Modal size="xl"  isOpen={modalModal} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal} tag="h4">
                      {!!isEdit ? "Detay" : " "}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                       

                      {getOrderDetail.map((item, index) => 
                         { 
                          return(
                            <Row key={index}>
                            <Col xl={12}>

                          <div className="d-flex gap-3" >

                            <div className="d-flex">
                           <Label className="form-label">
                            {item.Products.Products_Name} -                              {item.Products.Product_SubSubCategory.Product_SubSubCategory_Text}

                           </Label>

                     
                           </div>

                           <Label className="form-label">
                             {item.Quantity} Adet
                           </Label>

                           <Label className="form-label">
                             {item.TotalPrice} TL
                           </Label>


                           <div key={index}>
                              <img src={'https://megasubdomain.stechomeyazilim.info/' + item.Products.Products_Image} alt="" className="avatar-xl" />
                            </div>

                          



                          </div>
                          </Col>
                          </Row>
                        )})}
                    
                       
                        
                       
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
